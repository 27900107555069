import * as React from 'react';
import { FocusScope } from '@react-aria/focus';
import styles from './toolbar.module.scss';

interface ToolBarProps {
    className?: string;
    composeClassName?: string;
    children: React.ReactNode;
}
export const ToolBar = (props: ToolBarProps) => {
    const { children, className, composeClassName } = props;

    const classNames = [styles.toolbar, className, composeClassName].filter(Boolean).join(' ');

    return (
        <div role="toolbar" className={classNames}>
            <FocusScope>{children}</FocusScope>
        </div>
    );
};
