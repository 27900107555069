// React
import { Fragment, ReactElement, useState } from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, useAccordionItemContext } from '@reach/accordion';
// Next
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
// Global
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash.isempty';
// System Design
import { Button } from '@utmb/design-system/button/Button';
import { Col } from '@utmb/design-system/box/Box';
import { Font } from '@utmb/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Overlay, DismissButton } from '@utmb/design-system/overlay/Overlay';
import { Separator } from '@utmb/design-system/separator/Separator';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
import { XY } from '@utmb/design-system/xy/XY';
// Features
import { LanguageSelector } from '../language-selector/LanguageSelector';
import { MenuBar } from './mobile-utils/bottom-bar';
import { SocialShare } from '@utmb/features/layout/social-share/SocialShare';
import { TopBar } from './mobile-utils/top-bar';
import { UserInformation } from '@utmb/features/my-utmb/user-information/UserInformation';
import MegaNavMobile from '@utmb/features/mega-nav/MegaNavMobile';
// Interfaces
import { ColorTypesEnum } from '@utmb/types/colors';
import { GlobalNavigationMenuLevelView, GlobalNavigationMenuLevel2View, GlobalNavigationMenuLevel3View } from '@utmb/types/index';
import { IconsEnum } from '@utmb/types/icons';
import { LayoutProps } from '@utmb/layouts/layout-base';
// Utils
import { useUser } from '@utmb/contexts/AuthContext';
// Styles
import '@reach/accordion/styles.css';
import styles from './mobile-utils/mobile.module.scss';
import { getColorStyle } from '@utmb/utils/colors';
import { MyParameters } from '../my-parameters/MyParameters';

const { FuturaBook, FuturaBold, FuturaMedium } = Font;

export const Mobile = (
    props: Pick<LayoutProps, 'event' | 'navigation' | 'mobileBottomBar' | 'eventsTopBar' | 'languages' | 'translationSlugs'>
): ReactElement | null => {
    const { t } = useTranslation();

    const [status, setStatus] = useState(false);
    const close = (): void => {
        setStatus(false);
        setIsMegaNavOpen(false);
    };
    const open = (): void => setStatus(true);

    const { event, navigation, mobileBottomBar, eventsTopBar, languages, translationSlugs } = props;
    const { siteLogoDark } = event;
    const { children } = navigation;

    // mandatory root '/'
    const homeLink = children[0];
    // navigation with children need Accordion
    const withChildren = children.filter((tree: GlobalNavigationMenuLevelView) => !isEmpty(tree.children));

    // Find menu links elements
    const menuLinks = children.filter((tree: GlobalNavigationMenuLevelView) => !tree.children?.length && tree.slug !== '/');

    const router = useRouter();
    const navigate = async (destination: string): Promise<void> => {
        await router.push(destination);
        close();
    };

    // Get the user
    const { isAuthenticated, me, logout } = useUser();

    const [isMegaNavOpen, setIsMegaNavOpen] = useState(false);

    function handleMegaNavToggle(): void {
        const currentIsMegaNavOpen = !isMegaNavOpen;

        setIsMegaNavOpen(currentIsMegaNavOpen);
    }

    if (isEmpty(mobileBottomBar)) return null;

    const classesHeader = [styles.header, !isAuthenticated && styles.header_not_logged].filter(Boolean).join(' ');

    return (
        <>
            <MenuBar of={mobileBottomBar!} openTray={status ? close : open} isTrayOpen={status} className={styles.fixed} />
            {status && (
                <Overlay.Tray isOpen={true} isDismissable onClose={close} title="Menu">
                    <Col className={styles.scrollable}>
                        <Col className={styles.container}>
                            <TopBar handleMegaNavToggle={handleMegaNavToggle} />
                            <MegaNavMobile isOpen={isMegaNavOpen} eventsTopBar={eventsTopBar} handleMegaNavToggle={handleMegaNavToggle} />

                            <div className={classesHeader}>
                                <MyParameters className={styles.selector} />
                                <div className={styles.logo_container}>
                                    <Image image={siteLogoDark} layout="intrinsic" width={siteLogoDark.width} height={siteLogoDark.height} />
                                </div>
                                <LanguageSelector className={styles.selector} languages={languages} translationSlugs={translationSlugs} />
                            </div>
                            {isAuthenticated && me && (
                                <>
                                    <UserInformation user={me} />
                                    <Separator spacing="16px 0" opacity="0.3" />
                                </>
                            )}

                            {children[0].slug === '/' && <NonAccordionRow cb={navigate} {...homeLink} />}

                            <Accordion collapsible>
                                {withChildren.map((tree: GlobalNavigationMenuLevelView) => {
                                    return (
                                        <AccordionItem key={uuidv4()}>
                                            <AccordionRow {...tree} />
                                            <AccordionPanel className={styles.panel}>
                                                {tree.children?.map((subtree: GlobalNavigationMenuLevel2View) => {
                                                    if (!subtree.label) return;
                                                    if (subtree.children.length) {
                                                        return (
                                                            <Fragment key={uuidv4()}>
                                                                <Separator color={getColorStyle(ColorTypesEnum.WHITE)} spacing="1.5rem 0" />
                                                                <FuturaBold mobile="18">{subtree.label.toUpperCase()}</FuturaBold>
                                                                <Separator color={getColorStyle(ColorTypesEnum.WHITE)} spacing="1.5rem 0" />
                                                                {subtree.children.map((lastTree: GlobalNavigationMenuLevel3View) => {
                                                                    return (
                                                                        <Button.Ghost
                                                                            className={styles['flex-start']}
                                                                            key={uuidv4()}
                                                                            onPress={() => navigate(lastTree.slug!)}
                                                                            link={subtree.slug}
                                                                        >
                                                                            <FuturaBook mobile="16">{lastTree.label.toUpperCase()}</FuturaBook>
                                                                        </Button.Ghost>
                                                                    );
                                                                })}
                                                            </Fragment>
                                                        );
                                                    }
                                                    return (
                                                        <Button.Ghost leftAligned key={uuidv4()} onPress={() => navigate(subtree.slug!)} link={subtree.slug}>
                                                            <FuturaBook mobile="16">{subtree.label.toUpperCase()}</FuturaBook>
                                                        </Button.Ghost>
                                                    );
                                                })}
                                                <Separator color={getColorStyle(ColorTypesEnum.WHITE)} spacing="12px 0" />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    );
                                })}
                            </Accordion>

                            {!!menuLinks &&
                                menuLinks.map((tree: GlobalNavigationMenuLevelView) => {
                                    return <NonAccordionRow key={uuidv4()} cb={navigate} {...tree} />;
                                })}
                            <Spacer.size10 />
                            <SocialShare />
                            <Spacer custom="40" />

                            <Separator className={styles.separator} color="var(--utmb-color-border-gray)" />

                            {isAuthenticated && (
                                <a href="#" className={styles.menu_user_logout} onClick={logout}>
                                    <Icon variant={IconsEnum.LOGOUT} />
                                    <Font.FuturaBook className={styles.menu_user_logout_link} mobile="12" desktop="12" as="span">
                                        {t('common.signOut')}
                                    </Font.FuturaBook>
                                </a>
                            )}
                            <Spacer custom="100" />
                        </Col>
                    </Col>
                    <DismissButton onDismiss={close} />
                </Overlay.Tray>
            )}
        </>
    );
};

const AccordionRow = (tree: GlobalNavigationMenuLevelView) => {
    const { isExpanded } = useAccordionItemContext();
    return (
        <h3>
            <AccordionButton className={styles.row}>
                {tree.icon ? <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={tree.icon} /> : <span></span>}

                <FuturaMedium mobile="22" as="span" className={styles.spaced}>
                    {tree.label!.toUpperCase()}
                </FuturaMedium>
                <XY xy={isExpanded ? 'rotate(-180deg) translate(-2px, 2px)' : 'rotate(0) translate(2px, 0)'}>
                    <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={IconsEnum.CHEVRON} />
                </XY>
            </AccordionButton>
        </h3>
    );
};

/**
type SpecialProps = {
    label: string;
    defaultLabel?: string;
};

const AccordionRowSpecial = (props: SpecialProps): ReactElement => {
    const { defaultLabel, label } = props;
    const { isExpanded } = useAccordionItemContext();
    return (
        <>
            {defaultLabel && (
                <FuturaBold mobile="24" as="span">
                    {defaultLabel.toUpperCase()}
                </FuturaBold>
            )}
            <h3>
                <AccordionButton className={styles.row}>
                    <FuturaMedium mobile="24" as="span">
                        {label.toUpperCase()}
                    </FuturaMedium>
                    <XY xy={isExpanded ? 'rotate(-180deg) translate(-2px, 2px)' : 'rotate(0) translate(2px, 0)'}>
                        <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={IconsEnum.CHEVRON} />
                    </XY>
                </AccordionButton>
            </h3>
        </>
    );
};
*/

interface NonAccordionRowProps extends GlobalNavigationMenuLevelView {
    cb: (slug: string) => void;
}
const NonAccordionRow = (props: NonAccordionRowProps): ReactElement => {
    const { cb, label, slug, icon } = props;
    return (
        <h3>
            <Button.Ghost onPress={() => cb(slug)} className={styles.generic} link={slug}>
                {icon ? <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={icon} /> : <span></span>}
                <FuturaMedium mobile="24" as="span">
                    {label.toUpperCase()}
                </FuturaMedium>
            </Button.Ghost>
        </h3>
    );
};
