import { ReactElement, memo } from 'react';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Separator } from '@utmb/design-system/separator/Separator';
// Features
import { MyAccountDropdownSkeleton } from '@utmb/features/layout/my-account-dropdown/MyAccountDropdownSkeleton';
import { NavigationLinks } from '@utmb/features/layout/my-account-dropdown/my-account-dropdown-ui/NavigationLinks';
import { RegisterSignInButtons } from '@utmb/features/layout/my-account-dropdown/my-account-dropdown-ui/RegisterSignInButtons';
import { SignOutButton } from '@utmb/features/layout/my-account-dropdown/my-account-dropdown-ui/SignOutButton';
import { UserInformation } from '@utmb/features/my-utmb/user-information/UserInformation';
// Utils
import { useUser } from '@utmb/contexts/AuthContext';
import { useDashboardNavigation } from '@utmb/design-system/use-dashboard-navigation/UseDashboardNavigation';
// Styles
import styles from './my-account-dropdown.module.scss';

const cx = classNames.bind(styles);

interface MyAccountDropdownProps {
    isOpen?: boolean;
}

export const MyAccountDropdown = memo((props: MyAccountDropdownProps): ReactElement => {
    const { isLoginInProgress, isAuthenticated, me } = useUser();
    const { dashboardNavigation } = useDashboardNavigation();

    const { isOpen } = props;

    const containerClasses = cx(styles.container, {
        not_logged: !isLoginInProgress && !isAuthenticated,
        is_open: isOpen,
    });

    return (
        <div className={containerClasses}>
            {!isLoginInProgress ? (
                <>
                    {isAuthenticated && me ? (
                        <>
                            <UserInformation user={me} />
                            <Separator className={styles.separator} color="var(--utmb-color-border-gray)" />

                            {dashboardNavigation?.length ? <NavigationLinks /> : <MyAccountDropdownSkeleton />}

                            <SignOutButton />
                        </>
                    ) : (
                        <RegisterSignInButtons />
                    )}
                </>
            ) : (
                <MyAccountDropdownSkeleton />
            )}
        </div>
    );
});
