import * as React from 'react';

export type ShareIconVariants =
    | 'email'
    | 'facebook'
    // | 'instagram' // not in use
    // | 'strava' // not in use
    | 'twitter'
    | string;

type IcoProps = {
    /* Icon fill */
    color?: string;
    variant: ShareIconVariants;
};

export const SocialIcon = (props: IcoProps) => {
    const styles = {
        svg: {
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
    };

    const { color = 'currentColor', variant } = props;

    if (!variant) return null;
    return (
        <React.Fragment>
            {
                {
                    email: (
                        <svg style={{ ...styles.svg, marginRight: '6px' }} width="21" height="21" viewBox="0 0 21 21" focusable="false" aria-hidden="true">
                            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                <g stroke={color} strokeWidth="1.5">
                                    <path d="M3 16h16V6H3z" />
                                    <path d="M3 6l8 5 8-5" />
                                </g>
                            </g>
                        </svg>
                    ),
                    facebook: (
                        <svg style={{ ...styles.svg, marginRight: '6px' }} width="21" height="21" viewBox="0 0 21 21" focusable="false" aria-hidden="true">
                            <path
                                d="M6 11.63V8.48h2.587c0-.734-.025-1.421.008-2.106.026-.564.06-1.142.198-1.684.401-1.58 1.629-2.554 3.355-2.663.87-.055 1.748-.01 2.652-.01v2.918h-.77c-.298 0-.596-.009-.894.003-1.033.04-1.416.445-1.426 1.509-.006.657-.001 1.314-.001 2.012h3.01l-.393 3.151h-2.617v7.99H8.587v-7.97H6z"
                                fill={color}
                                fillRule="evenodd"
                            />
                        </svg>
                    ),
                    // instagram: (
                    //     <svg style={{ ...styles.svg, marginRight: '6px' }} width="21" height="21" viewBox="0 0 21 21" focusable="false" aria-hidden="true">
                    //         <g fill="none" fillRule="evenodd">
                    //             <g fill={color}>
                    //                 <path d="M10.325 3.817v.033c-1.005 0-2.01-.009-3.014.004-.63.008-1.254.07-1.85.301-.689.268-1.174.734-1.423 1.44-.187.53-.227 1.082-.242 1.631a152.704 152.704 0 00-.06 3.828c-.006 1.1.01 2.201.16 3.293.08.569.296 1.08.721 1.485.507.482 1.138.683 1.81.754a15.44 15.44 0 001.624.083c1.843.001 3.686-.011 5.528-.025a4.65 4.65 0 001.527-.261c.725-.26 1.24-.733 1.498-1.474.184-.525.227-1.072.24-1.617.033-1.533.057-3.066.052-4.6-.003-.922-.01-1.85-.198-2.759-.167-.801-.623-1.385-1.378-1.72-.54-.24-1.113-.33-1.695-.345-1.1-.027-2.2-.035-3.3-.05m-.002 14.822c-1.219-.018-2.44-.008-3.657-.063-1.157-.052-2.204-.438-3.071-1.237-.592-.545-.98-1.217-1.217-1.981-.234-.754-.274-1.535-.31-2.314a55.935 55.935 0 01.004-5.6c.033-.64.079-1.288.214-1.912.308-1.424 1.152-2.436 2.508-3 .612-.255 1.254-.374 1.912-.428 1.965-.165 3.934-.09 5.899-.08.627.003 1.257.06 1.879.146 1.184.162 2.232.617 3.016 1.562.45.54.746 1.163.866 1.852.096.552.166 1.112.195 1.671a58.246 58.246 0 010 6.07c-.03.588-.08 1.183-.207 1.756-.323 1.448-1.167 2.494-2.547 3.081-.765.326-1.57.442-2.397.44h-3.087v.037" />
                    //                 <path d="M8.236 10.643a2.412 2.412 0 002.412 2.4 2.405 2.405 0 002.386-2.408 2.39 2.39 0 00-2.408-2.394 2.398 2.398 0 00-2.39 2.402m-1.756-.409c-.013-1.368 1.142-2.962 2.838-3.537a4.132 4.132 0 014.403 1.165c.939 1.056 1.276 2.292.969 3.661-.348 1.55-1.316 2.596-2.848 3.089-1.567.504-3.32-.031-4.369-1.28-.657-.782-.99-1.68-.993-3.098m7.04-4.07a.953.953 0 01.96-.964c.525 0 .957.43.96.956a.964.964 0 01-.966.964.952.952 0 01-.954-.956" />
                    //             </g>
                    //         </g>
                    //     </svg>
                    // ),
                    // strava: (
                    //     <svg style={{ ...styles.svg, marginRight: '6px' }} width="21" height="21" viewBox="0 0 21 21" focusable="false" aria-hidden="true">
                    //         <g fill="none" fillRule="evenodd">
                    //             <path
                    //                 fill={color}
                    //                 d="M13.569 15.464l1.814-3.52h-3.656l1.842 3.52M9.178 0c.078.142.13.234.18.327 1.97 3.764 3.943 7.527 5.907 11.295.101.195.21.271.434.267.808-.013 1.617-.005 2.501-.005L13.582 20.8l-4.657-8.897h2.774L9.203 7.127c-.697 1.347-1.37 2.65-2.046 3.952-.145.28-.235.657-.471.804-.226.14-.608.043-.921.043-.901.002-1.802.001-2.765.001L9.178 0"
                    //             />
                    //         </g>
                    //     </svg>
                    // ),
                    twitter: (
                        <svg style={{ ...styles.svg, marginRight: '6px' }} width="21" height="21" viewBox="0 0 21 21" focusable="false" aria-hidden="true">
                            <g fill="none" fillRule="evenodd">
                                <path
                                    fill={color}
                                    d="M13.84 3h1.017c.803.18 1.534.502 2.133 1.103.056.057.205.06.293.03.692-.23 1.38-.472 2.071-.712-.047.119-.084.309-.185.449-.374.52-.77 1.022-1.147 1.515l1.835-.528c-.128.188-.2.332-.307.441-.426.438-.872.856-1.29 1.301a.853.853 0 00-.21.458c-.101.846-.094 1.716-.277 2.541-1.405 6.328-7.417 9.622-13.251 7.985A7.565 7.565 0 012 16.353a7.538 7.538 0 002.763-.27c.899-.265 1.736-.664 2.52-1.323-1.681-.188-2.797-1.02-3.396-2.575l1.506-.08C3.49 11.24 2.45 9.852 2.618 8.325c1.073.43 1.101.437 1.487.355-1.51-1.466-1.97-3.83-.95-4.924C5.147 6.13 7.65 7.455 10.734 7.67c-.014-.177-.02-.3-.034-.422-.16-1.38.311-2.5 1.32-3.402.525-.469 1.17-.666 1.82-.846"
                                />
                            </g>
                        </svg>
                    ),
                }[variant]
            }
        </React.Fragment>
    );
};
