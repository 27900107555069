// React
import { ReactElement } from 'react';
// Next
import { useRouter } from 'next/router';
// Modules
import isEmpty from 'lodash.isempty';
// Design system
import { DropDown, DropDownItem } from '@utmb/design-system/dropdown/Dropdown';
import { Font } from '@utmb/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
// Interfaces
import { IconsEnum } from '@utmb/types/icons';

const { FuturaMedium } = Font;

interface Props {
    className?: string;
    languages: string[];
    translationSlugs?: {
        [key: string]: string;
    };
}

export const LanguageSelector = (props: Props): ReactElement | null => {
    const { languages } = props;

    const { locales, asPath, locale, push } = useRouter();

    if (!locale || !locales || isEmpty(locales) || languages?.length <= 1) return null;
    const displayAllButActive = locales.filter((lang: string) => lang !== locale && languages?.includes(lang));

    const dynamicTransition = (lang: string): void => {
        push(asPath, asPath, { locale: lang });
    };

    return (
        <DropDown
            button={({ isExpanded }) => {
                return (
                    <>
                        <FuturaMedium mobile="12">{locale.toUpperCase()}</FuturaMedium>
                        <Icon variant={IconsEnum[isExpanded ? 'CHEVRON_UP' : 'CHEVRON_DOWN']} />
                    </>
                );
            }}
            className={props.className ? props.className : undefined}
        >
            {displayAllButActive?.map((lang: string) => (
                <DropDownItem key={lang} onSelect={() => dynamicTransition(lang)} className={locale === lang ? 'font-bold' : undefined}>
                    <FuturaMedium mobile="12" as="span">
                        {lang}
                    </FuturaMedium>
                </DropDownItem>
            ))}
        </DropDown>
    );
};
