import { ReactElement } from 'react';
// Custom modules
import { default as NextImage, ImageProps as NextImageProps, ImageLoader as NextImageLoader, ImageLoaderProps as NextImageLoaderProps } from 'next/image';
// Utils
import { useCloudinary } from 'src/design-system/use-cloudinary/UseCloudinary';
// Interfaces
import { CloudinaryMediaView } from 'src/types';
import { CloudinaryFiltersEnum, CloudinaryTransformEnum } from '@utmb/types/cloudinary-transform.enum';

interface ImageProps extends Omit<NextImageProps, 'src'> {
    image: CloudinaryMediaView;
    maxWidth?: number;
    quality?: number;
    addOptions?: CloudinaryTransformEnum[];
    addFilters?: Record<string, any>;
}

const loader = ({ src, width, quality }: NextImageLoaderProps): string => src;

export const Image = (props: ImageProps): ReactElement | null => {
    const { image, layout = 'fill', maxWidth, quality, addOptions, addFilters, ...rest } = props;
    const { getImage, getImageBlurred } = useCloudinary();

    if (!image || !image.publicId) return null;

    return (
        <NextImage
            loader={loader}
            src={getImage(image.publicId, { maxWidth, quality, addOptions, addFilters })}
            placeholder="blur"
            blurDataURL={getImageBlurred(image.publicId, image.ratio)}
            alt={image.alt}
            layout={layout}
            unoptimized={true}
            {...rest}
        />
    );
};
