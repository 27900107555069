// Next Modules
import NextLink from 'next/link';
import dynamic from 'next/dynamic';
// Types
import { ColorTypesEnum } from '@utmb/types/colors';
// Utils
import { getColorAndBgColorButton, getColorStyle, isColorTypesEnum } from '@utmb/utils/colors';
// Design System
import { IcoAnimated as ComponentType, IcoAnimatedProps } from 'src/design-system/animated-icon/AnimatedIcon';
import { Font } from 'src/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
// Context
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Interfaces
import { CoreProps } from '../types';
import { IconsEnum } from '@utmb/types/icons';
// Styles
import styles from './link.module.scss';
import { useEffect, useState } from 'react';

interface LinkProps extends CoreProps {
    /** Open link as */
    target?: 'self' | '_blank';
    /** https://nextjs.org/docs/api-reference/next/link  */
    locale?: false | string | undefined;
    /** Destination */
    href: string;
    /** External links */
    prefetch?: boolean;
    rel?: string;
    style?: {
        [key: string]: string;
    };
    underline?: boolean;
    onClick?: (event: any) => any;
    onFocus?: (event: any) => any;
    onBlur?: (event: any) => any;
    onMouseEnter?: (event: any) => any;
    onMouseLeave?: (event: any) => any;
}

interface LinkDefaultProps extends LinkProps {
    label: string;
    color?: string | ColorTypesEnum;
    variant?:
        | 'download'

        // Arrow Up
        | 'up'

        // Arrow Down
        | 'down'

        // Arrow Left
        | 'left'

        // Arrow Right
        | 'right'

        // Chevron Down
        | 'chevronDown'

        // Chevron Up
        | 'chevronUp'
        | string;
    underline?: boolean;
}

interface LinkButtonProps extends LinkDefaultProps {
    bgcolor?: string | ColorTypesEnum;
    underline?: boolean;
    isOnSection?: boolean;
    alignEnd?: boolean;
}

const hasTarget = (props: LinkProps): props is LinkProps => 'target' in props;

// -----------------------------------------
//
// # Link
//
// # Props
//
//   href: string;
//   locale?: string;
//   target?: string;
//   rel?: string;
//   style?;
//

export const Link = (props: LinkProps) => {
    const { children, className, composeClassName, followClassName, locale, underline, href: hrefInitial, ...extra } = props;
    let { target, prefetch } = props;
    const [href, setHref] =  useState<string>(hrefInitial?.trim() ?? '');
    const classNames = [styles.link, className, composeClassName, followClassName].filter(Boolean).join(' ');
    //const user = useUser();
    //const isRegisterLink = hrefInitial?.includes('register-utmb.world');
    let rel = hasTarget(props) && target === '_blank' ? 'noopener nofollow' : undefined;

    if (!target && (href.startsWith('http://') || href.startsWith('https://') || href.startsWith('mailto:'))) {
        target = '_blank';
        rel = 'noopener nofollow';
    }
    if(href.includes('utmb.world')) rel=undefined;

    useEffect(() => {
        setHref(hrefInitial?.trim() ?? '');
        /*if(isRegisterLink) {
            if (user && !user.isLoginInProgress && (!user.isAuthenticated || !user.me?.email)) {
                const lang = (locale ?? LangEnum.EN) === LangEnum.EN ? '' : `/${locale}`;
                const newHref = user.getLoginUrl(`https://utmb.world${lang}/my-utmb/registration/information?redirect_uri=${encodeURIComponent(href)}`);
                setHref(newHref);
            }
        }*/
    }, [hrefInitial/*, isRegisterLink, user, locale*/]);

    if(!href) return null;
    else { /*
        return isRegisterLink ? (
        <a className={classNames} href={href} {...extra} target={target ? '_blank' : undefined} rel={rel}>
            {children}
        </a>
    ) : */
        return (
            <NextLink href={href} locale={locale} prefetch={prefetch}>
                <a className={classNames} href={href} {...extra} target={target ? '_blank' : undefined} rel={rel}>
                    {children}
                </a>
            </NextLink>
        );
    }
};

// -----------------------------------------
//
// # Link Default (with Arrow Right / NO BACKGROUND)
//
// # Props
//
//   label: string;
//   href: string;
//   variant?: string;
//   color? string;
//

Link.Default = (props: LinkDefaultProps) => {
    const { label, color, variant, underline = true } = props;
    const linkDefaultCSS = [styles.link_default, underline === false ? styles.link_default_nu : ''].filter(Boolean).join(' ');

    const style: any = {};

    if (color) {
        style['color'] = getColorStyle(color);
    }

    return (
        <Link className={linkDefaultCSS} style={style} {...props}>
            <Font.FuturaBook className={styles.link_default_label} mobile="14" desktop="16" as="span">
                {label}
            </Font.FuturaBook>

            {variant && (
                <NoSSRAnimatedIcon rotation={20} timing={200}>
                    <Icon variant={variant as IconsEnum} />
                </NoSSRAnimatedIcon>
            )}
        </Link>
    );
};

// -----------------------------------------
//
// # Link as Button
//
// # Props
//
//   label: string;
//   href: string;
//   variant?: string;
//   color?: string;
//   bgcolor?: string;
//

Link.Button = (props: LinkButtonProps) => {
    const {
        global: {
            event: { primaryColorIsDark, secondaryColorIsDark },
        },
    } = useGlobal();
    let { color, bgcolor } = props;
    if (!color || !bgcolor) {
        [color, bgcolor] = getColorAndBgColorButton(primaryColorIsDark, secondaryColorIsDark, bgcolor && isColorTypesEnum(bgcolor) ? bgcolor as ColorTypesEnum : null);
    }
    const {
        label,
        variant,
        underline = false,
        isOnSection = false,
        alignEnd = false,
    } = props;

    const linkButtonCSS = [styles.link_default, styles.link_button, underline === false ? styles.link_default_nu : ''].filter(Boolean).join(' ');

    return (
        <div
            className={[
                styles.link_container,
                isOnSection ? styles.link_container_start : alignEnd ? styles.link_container_end : styles.link_container_center,
            ].join(' ')}
        >
            <Link className={linkButtonCSS} style={{ color: getColorStyle(color) }} {...props}>
                <div className={styles.link_button_container} style={{ backgroundColor: getColorStyle(bgcolor) }}>
                    <Font.FuturaHeavy className={styles.link_button_label} mobile="14" desktop="16" as="span">
                        {label}
                    </Font.FuturaHeavy>

                    {variant && (
                        <NoSSRAnimatedIcon rotation={20} timing={200}>
                            <Icon variant={variant as IconsEnum} />
                        </NoSSRAnimatedIcon>
                    )}
                </div>
            </Link>
        </div>
    );
};

// -----------------------------------------

const NoSSRAnimatedIcon = dynamic<IcoAnimatedProps>(() => import('../animated-icon/AnimatedIcon').then((comp) => comp.IcoAnimated as any), {
    ssr: false,
}) as typeof ComponentType;
