import { ReactElement, useEffect, useState } from 'react';
// Modules
import Marquee from 'react-fast-marquee';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Link } from '@utmb/design-system/link/link';
// Utils
import { useTranslation } from 'next-i18next';
// Interfaces
import { IconsEnum } from '@utmb/types/icons';
import { LiveBannerProps } from '@utmb/types/live-banner';
// Styles
import styles from './live-banner.module.scss';

export const LiveBanner = ({ event, position, liveActive = 0, nextLiveActive }: LiveBannerProps): ReactElement | null => {
    const [translateY, setTranslateY] = useState(0);
    const { t } = useTranslation();
    const { urlLive, title } = event;

    useEffect(() => {
        const animationDuration = parseInt(styles.animationDuration) || 1000;
        // if current, coming from bottom.
        // if previous, leaving to the top.
        const direction = position === liveActive ? liveActive * -100 : (position + 1) * -100;
        setTranslateY(direction);

        // if next to come, ...
        const isNextLiveActive = nextLiveActive !== undefined && nextLiveActive === position;

        // and not the same (case there is only 1 live), ...
        if (isNextLiveActive && liveActive !== nextLiveActive) {
            // ... set position at the bottom to keep the way they're showing up
            setTimeout(() => {
                setTranslateY((position + 1) * 100);
            }, animationDuration);
        }
    }, [liveActive]);

    /* DO NOT REMOVE OPACITY EFFECT */
    const style = {
        opacity: liveActive === position ? 1 : 0,
        transform: `translateY(${translateY}%)`,
    };

    return (
        <Marquee gradient={false} className={styles.marquee_container} style={style}>
            <Link href={`${urlLive}?utm_source=referral&utm_medium=world&utm_campaign=live-banner`} className="liveBanner">
                <Font.OxaniumBold mobile="12" desktop="13" as="span">
                    {t('common.topBarLiveEvent')} {title}
                    <span className={styles.live_icon}>
                        <Icon variant={IconsEnum.LIVE} />
                    </span>
                </Font.OxaniumBold>
            </Link>
        </Marquee>
    );
};
