import * as React from 'react';

interface BoxProps<C extends React.ElementType> {
    /** HTML tag or Component */
    as?: C;
    children?:
        | ((...args: (React.ReactNode | React.ReactElement | Element | null)[]) => React.ReactElement | null)
        | React.ReactNode
        | React.ReactElement
        | Element
        | null;
    /** Accepts string and styles.container from module.scss */
    className?: string;
    composeClassName?: string;
    /** For parallax/animations  */
    onMouseMove?: (event: React.MouseEvent) => void;
    /** A11y */
    id?: string;
    role?: string;
    style?: {
        [key: string]: string;
    };
}

export const Box = <C extends React.ElementType = 'div'>({
    as,
    children,
    className = undefined,
    composeClassName = undefined,
    ...rest
}: BoxProps<C>): React.ReactElement => {
    const Tag = as || 'div';
    const clss = [className, composeClassName].filter(Boolean).join(' ');
    return (
        <Tag className={clss} {...rest}>
            {children}
        </Tag>
    );
};

export const Row = <C extends React.ElementType = 'div'>(props: BoxProps<C>): React.ReactElement => <Box {...props} composeClassName="flex" />;

export const Col = <C extends React.ElementType = 'div'>(props: BoxProps<C>): React.ReactElement => <Box {...props} composeClassName="flex col" />;

export const FlexGrid = <C extends React.ElementType = 'div'>(props: BoxProps<C>): React.ReactElement => <Box {...props} composeClassName="flex row-wrap " />;

export const ColToRow = <C extends React.ElementType = 'div'>(props: BoxProps<C>): React.ReactElement => <Box {...props} composeClassName="flex-landscape" />;
