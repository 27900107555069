// React
import { ReactElement } from 'react';
// Design System
import { ToolBar } from 'src/design-system/toolbar/Toolbar';
// Features
import { BottomBarElement } from './bottomBarElement';
// Contexts
import { useTranslation } from 'next-i18next';
import { useUser } from '@utmb/contexts/AuthContext';
// Types
import { IconsEnum } from '@utmb/types/icons';
import { MenuBarProps } from '@utmb/types/mobile-bottom-bar';
// Styles
import styles from './mobile.module.scss';

export const MenuBar = (props: MenuBarProps): ReactElement => {
    const { className, of, openTray, isTrayOpen } = props;

    const { children } = of;

    const user = useUser();
    const { t } = useTranslation();

    const toolBarClassNames = [styles.bar, className].filter(Boolean).join(' ');

    const toggleMenu = (event: any): void => {
        event.preventDefault();
        openTray();
    };

    const closeMenuIfOpen = (): void => {
        if (isTrayOpen) {
            openTray();
        }
    };

    return (
        <ToolBar className={toolBarClassNames}>
            <BottomBarElement label={t('common.homepage')} icon={IconsEnum.HOME} onClick={closeMenuIfOpen} />
            {children.length >= 1 && <BottomBarElement label={children[0].label} icon={children[0].icon} link={children[0].slug} />}
            <BottomBarElement label={t('common.menu')} icon={isTrayOpen ? IconsEnum.CLOSE : IconsEnum.BURGER} onClick={toggleMenu} />
            {children.length >= 2 && <BottomBarElement label={children[1].label} icon={children[1].icon} link={children[1].slug} />}
            <BottomBarElement
                label={!user.isAuthenticated ? t('common.signIn') : t('myAccount.dashboard')}
                icon={IconsEnum.USER}
                link={!user.isAuthenticated ? '#' : '/my-utmb/my-dashboard'}
                onClick={!user.isAuthenticated ? user.login : closeMenuIfOpen}
            />
        </ToolBar>
    );
};
