import { ReactElement } from 'react';
// Design System
import { Separator } from '@utmb/design-system/separator/Separator';
import { Skeleton } from '@utmb/design-system/placeholder/Placeholder';
// Styles
import styles from './my-account-dropdown-skeleton.module.scss';

export const MyAccountDropdownSkeleton = (): ReactElement => {
    return (
        <div className={styles.container}>
            <div className={styles.skeleton_user_information}>
                <div className={styles.skeleton_image_container}>
                    <Skeleton width="100%" height="80" />
                </div>
                <div className={styles.skeleton_content}>
                    <Skeleton className={styles.skeleton_name} width="75%" height="18" />
                    <Skeleton width="60%" height="14" />
                </div>
            </div>
            <Separator className={styles.separator} color="var(--utmb-color-border-gray)" />

            <div className={styles.loading_container}>
                {[...new Array(5)].map((item, index) => (
                    <Skeleton key={index} height="14" className="mb-4" />
                ))}
            </div>
            <Separator className={styles.separator} color="var(--utmb-color-border-gray)" />
            <div className={styles.skeleton_btn_wrapper}>
                <Skeleton width="50%" height="45" />
            </div>
        </div>
    );
};
