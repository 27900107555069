import * as React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, MenuPopover } from '@reach/menu-button';
import { CoreProps, RenderProp } from '../types';

type DropDownProps = {
    portal?: boolean;
    direction?: 'vertical' | 'horizontal';
    button: RenderProp<{ isExpanded: boolean }>;
} & CoreProps;

const DropDown = (props: DropDownProps) => {
    const { children, button, portal = false, direction = 'vertical' } = props;
    const classNames = ['drop-down', props.className, props.composeClassName, direction === 'horizontal' && 'is-horizontal'].filter(Boolean).join(' ');
    return (
        <div className={classNames}>
            <Menu>
                {({ isExpanded }) => (
                    <React.Fragment>
                        <MenuButton>
                            {button({
                                isExpanded,
                            })}
                        </MenuButton>
                        <MenuPopover portal={portal}>
                            <MenuItems>{children}</MenuItems>
                        </MenuPopover>
                    </React.Fragment>
                )}
            </Menu>
        </div>
    );
};

export { DropDown, MenuItem as DropDownItem };
