import { ReactElement, memo } from 'react';
// Styles
import styles from './my-parameters.module.scss';
import { useTranslation } from 'next-i18next';
import { usePreference } from '@utmb/contexts/PreferenceContext';
import { UnitEnum } from '@utmb/types/unit-enum';
import { Font } from '@utmb/design-system/text/font';
import { DropDown, DropDownItem } from '@utmb/design-system/dropdown/Dropdown';
import { Icon } from '@utmb/design-system/icons/Icon';
import { IconsEnum } from '@utmb/types/icons';

interface Props {
    className?: string;
}

export const MyParameters = memo((props: Props): ReactElement => {
    const { t } = useTranslation();

    const {
        preferences: { preferredUnit },
        setPreferredUnit,
    } = usePreference();

    return (
        <DropDown
            button={({ isExpanded }) => {
                return (
                    <>
                        <Font.FuturaMedium mobile="12">{preferredUnit === UnitEnum.IMPERIAL ? 'miles' : 'km'}</Font.FuturaMedium>
                        <Icon variant={IconsEnum[isExpanded ? 'CHEVRON_UP' : 'CHEVRON_DOWN']} />
                    </>
                );
            }}
            className={`${props.className ? props.className : undefined} ${styles.preferences}`}
        >
            <DropDownItem key="void" onSelect={() => null} disabled>
                <Font.FuturaBold mobile="12" as="span">
                    {t('common.pref.unit.title')}
                </Font.FuturaBold>
            </DropDownItem>
            {[UnitEnum.METRIC, UnitEnum.IMPERIAL].map((unit: UnitEnum) => (
                <DropDownItem key={unit} onSelect={() => setPreferredUnit(unit)}>
                    <Font.FuturaMedium mobile="12" as="span">
                        {unit === UnitEnum.IMPERIAL ? `${t('common.pref.unit.imperial')} (miles / feet)` : `${t('common.pref.unit.metric')} (km / m)`}
                    </Font.FuturaMedium>
                </DropDownItem>
            ))}
        </DropDown>
    );
});
