import { ReactElement } from 'react';
import { Accordion as RAccordion, AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion';
// Design system
import { Font } from 'src/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
// Types
import { EventView, CloudinaryMediaView } from '@utmb/types/index';
import { ColorTypesEnum } from '@utmb/types/colors';
// Utils
import { getColorStyle } from '@utmb/utils/colors';
// Styles
import styles from './mega-nav.module.scss';
import { CloudinaryTransformEnum } from '@utmb/types/cloudinary-transform.enum';
import { IconsEnum } from '@utmb/types/icons';

interface MegaNavProps {
    isOpen: boolean;
    eventsTopBar: EventView[];
    handleMegaNavToggle: () => void;
}

const MegaNavMobile = (props: MegaNavProps): ReactElement => {
    const { isOpen, eventsTopBar, handleMegaNavToggle } = props;

    const eventsByContinents: Record<string, EventView[]> = {};

    for (const event of eventsTopBar) {
        if (!(event.continent in eventsByContinents)) {
            eventsByContinents[event.continent] = [];
        }

        eventsByContinents[event.continent].push(event);
    }

    const containerCSS = [styles.root_mobile, isOpen && styles.show].filter(Boolean).join(' ');

    return (
        <div className={containerCSS}>
            <div className={`${styles.container_mobile} container`}>
                <button className={styles.close} onClick={handleMegaNavToggle} onKeyDown={handleMegaNavToggle}>
                    <Icon variant={IconsEnum.CLOSE} />
                </button>
                <Spacer.size30 />
                <Font.FuturaBold className={styles.title} mobile="24">
                    UTMB
                </Font.FuturaBold>
                <Font.FuturaMedium className={styles.title} mobile="24">
                    WORLD SERIES EVENT
                </Font.FuturaMedium>
                <Spacer custom="60" />

                {Object.values(eventsByContinents).map((event, indexContinent: number) => (
                    <div key={indexContinent} className={`${styles.row} row`}>
                        <div className="col-md-10 offset-md-1">
                            <RAccordion collapsible multiple>
                                <AccordionItem>
                                    <h3>
                                        <AccordionButton>
                                            <Font.FuturaHeavy mobile="24" as="span">
                                                {event[0].continent}
                                            </Font.FuturaHeavy>
                                            <Icon
                                                color={getColorStyle(ColorTypesEnum.WS_SECONDARY)}
                                                variant={IconsEnum.CHEVRON_UNCONSTRAINED}
                                                clss={styles.svg}
                                            />
                                        </AccordionButton>
                                    </h3>
                                    <AccordionPanel>
                                        {event.map((race, indexEvent: number) => {
                                            const media = {
                                                publicId: race.picture,
                                            } as CloudinaryMediaView;

                                            const logo = {
                                                publicId: race.logo,
                                            } as CloudinaryMediaView;

                                            return (
                                                <div key={indexEvent} className={styles.image_wrapper_mobile}>
                                                    <Link href={race.url}>
                                                        <div className={styles.overlay}>
                                                            <div className={styles.date}>
                                                                {race?.dateFormated && (
                                                                    <Font.OxaniumBold desktop="14" className="lh-d-24">
                                                                        {race.dateFormated}
                                                                    </Font.OxaniumBold>
                                                                )}
                                                            </div>
                                                            <div className={styles.utmb_icon}>
                                                                {race.logo ? (
                                                                    <Image
                                                                        objectFit="contain"
                                                                        layout="fill"
                                                                        image={logo}
                                                                        maxWidth={100}
                                                                        addOptions={[CloudinaryTransformEnum.TRIM]}
                                                                    />
                                                                ) : (
                                                                    <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={IconsEnum.UTMB_GROUP} />
                                                                )}
                                                            </div>
                                                            <div className={styles.flag}>
                                                                {race?.countryCode && <span className={`fi fi-${race.countryCode.toLowerCase()}`} />}
                                                            </div>
                                                        </div>
                                                        {!race?.picture ? (
                                                            <img
                                                                className={styles.placeholder}
                                                                src="https://via.placeholder.com/400x150/00DBFF/000000?text=Placeholder"
                                                                alt="placeholder"
                                                            />
                                                        ) : (
                                                            <Image className={styles.image} objectFit="cover" layout="fill" image={media} maxWidth={300} />
                                                        )}
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </AccordionPanel>
                                </AccordionItem>
                            </RAccordion>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MegaNavMobile;
