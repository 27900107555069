// React
import { ReactElement } from 'react';
// Utils
import { rem } from '@utmb/utils/font';

const availableSizes = {
    // v.1.0 - TO BE REMOVED
    xs: 'spacer-xs',
    s: 'spacer-s',
    default: 'spacer-default',
    m: 'spacer-m',
    l: 'spacer-l',
    xl: 'spacer-xl',
    xxl: 'spacer-xxl',
    // v.2.0
    s10: 'spacer-10',
    s20: 'spacer-20',
    s30: 'spacer-30',
    s40: 'spacer-40',
    s50: 'spacer-50',
} as const;

interface SpacerProps {
    size?: 'xs' | 's' | 'default' | 'm' | 'l' | 'xl' | 'xxl' | 's10' | 's20' | 's30' | 's40' | 's50';
    custom?: string;
    className?: string;
}

export const Spacer = ({ className, size = 'default', custom }: SpacerProps): ReactElement => {
    const space = availableSizes[size as keyof typeof availableSizes];
    const composedClassName = space ? 'flex self-grow ' + space : undefined;
    const classNames = [composedClassName, className].filter(Boolean).join(' ');
    return (
        <div
            aria-hidden="true"
            className={classNames}
            style={{
                ...(custom && {
                    minHeight: rem(custom),
                }),
            }}
        />
    );
};

// v.1.0 - TO BE REMOVED
Spacer.XS = (props: SpacerProps) => <Spacer {...props} size="xs" />;
Spacer.S = (props: SpacerProps) => <Spacer {...props} size="s" />;
Spacer.M = (props: SpacerProps) => <Spacer {...props} size="m" />;
Spacer.L = (props: SpacerProps) => <Spacer {...props} size="l" />;
Spacer.XL = (props: SpacerProps) => <Spacer {...props} size="xl" />;
Spacer.XXL = (props: SpacerProps) => <Spacer {...props} size="xxl" />;

// v.2.0
Spacer.size10 = (props: SpacerProps) => <Spacer {...props} size="s10" />;
Spacer.size20 = (props: SpacerProps) => <Spacer {...props} size="s20" />;
Spacer.size30 = (props: SpacerProps) => <Spacer {...props} size="s30" />;
Spacer.size40 = (props: SpacerProps) => <Spacer {...props} size="s40" />;
Spacer.size50 = (props: SpacerProps) => <Spacer {...props} size="s50" />;

export const Br = (): ReactElement => <br aria-hidden="true" />;
