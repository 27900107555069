import { ReactElement, useEffect, useState } from 'react';
// Features
import { LiveBanner } from '@utmb/features/live-banner/LiveBanner';
// Contexts
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Utils
import EventView from '@utmb/types/view/EventView';
// Styles
import styles from './live-banners.module.scss';
import stylesLiveBanner from '@utmb/features/live-banner/live-banner.module.scss';

export const LiveBanners = (): ReactElement | null => {
    const [liveActive, setLiveActive] = useState(0);
    const { global } = useGlobal();
    const { eventsTopBar } = global;

    const lives: EventView[] = eventsTopBar.filter((evt) => evt.isLive && evt.urlLive);

    const nextLiveActive = (): number => (liveActive + 1 >= lives.length ? 0 : liveActive + 1);

    useEffect(() => {
        document.body.classList[lives.length ? 'add' : 'remove']('-has-lives');
    }, []);

    useEffect(() => {
        // How long transition animation take time
        const animationDuration = parseInt(stylesLiveBanner.animationDuration) || 3000;
        // How long stay visible a live item
        let visibilityDuration = parseInt(stylesLiveBanner.visibilityDuration) || 5000;

        /**
         * SECURITY
         * Set a visibility duration more or equal than three times animation duration
         */
        visibilityDuration = visibilityDuration < animationDuration * 3 ? animationDuration * 3 : visibilityDuration;

        // If there are multiple lives, init animation
        if (lives.length > 1) {
            const interval = window.setInterval(() => {
                const newLiveActive = nextLiveActive();
                setLiveActive(newLiveActive);
            }, visibilityDuration);
            return () => clearInterval(interval);
        }
    }, [liveActive]);

    if (!lives.length) return null;

    return (
        <div className={styles.root}>
            {lives.map((live: EventView, key) => (
                <LiveBanner event={live} liveActive={liveActive} nextLiveActive={nextLiveActive()} position={key} key={key} />
            ))}
        </div>
    );
};
