import { ElementType } from 'react';
import { WithChildren } from '../types';
import { rem } from '@utmb/utils/font';

type XYProps<C extends ElementType> = {
    as?: C;
    /* Some times you need to place items 'under' other */
    z?: number;
    /* Pixel adjust with css transforms */
    x?: number;
    y?: number;
    /* Translate with percentages*/
    xy?: string;
    // Filter style
    filter?: boolean;
} & WithChildren;

export const XY = <C extends ElementType = 'div'>(props: XYProps<C>) => {
    const { as, children, x, y, xy, z, filter = false } = props;
    const Tag = as || 'div';
    let transformStyle;
    if (xy) {
        transformStyle = xy;
    } else {
        transformStyle = `translate(${rem(x || 0)}, ${rem(y || 0)})`;
    }
    return (
        <Tag
            style={{
                transform: transformStyle,
                width: filter ? '' : '100%',
                ...(z && {
                    zIndex: z,
                    position: 'relative',
                }),
            }}
        >
            {children}
        </Tag>
    );
};

XY.defaultProps = {
    x: 0,
    y: 0,
    as: 'div',
};
