import * as React from 'react';
import { useOverlay, usePreventScroll, useModal, DismissButton } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { CoreProps, WithChildren } from '../types';

type ModalProps = CoreProps &
    WithChildren & {
        title?: string;
    };

export const Modal = (props: ModalProps) => {
    const { title, children, className, composeClassName, followClassName, ...rest } = props;
    const modalClassNames = ['modal', className, composeClassName].filter(Boolean).join(' ');

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    const ref: unknown = React.useRef();
    const { overlayProps } = useOverlay(rest, ref as React.RefObject<HTMLDivElement>);

    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventScroll();
    const { modalProps } = useModal();

    const { dialogProps, titleProps } = useDialog(rest, ref as React.RefObject<HTMLDivElement>);

    return (
        <div className="underlay">
            <FocusScope contain restoreFocus autoFocus>
                <div {...overlayProps} {...dialogProps} {...modalProps} ref={ref as React.RefObject<HTMLDivElement>} className={modalClassNames}>
                    <p {...titleProps} className="modal-title">
                        {title}
                    </p>
                    {children}
                </div>
            </FocusScope>
        </div>
    );
};
