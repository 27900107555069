// React
import { ReactElement } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Link } from '@utmb/design-system/link/link';
import { Separator } from '@utmb/design-system/separator/Separator';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
// Interfaces
import { ColorTypesEnum } from '@utmb/types/colors';
// Styles
import styles from '../my-account-dropdown.module.scss';
import { useUser } from '@utmb/contexts/AuthContext';

export const RegisterSignInButtons = (): ReactElement => {
    const { login, register } = useUser();

    const { t } = useTranslation();

    return (
        <>
            <div className={styles.register_container}>
                <Font.FuturaHeavy desktop="16" as="p">
                    {t('myAccount.joinTheAdventure')}
                </Font.FuturaHeavy>
                <Spacer />
                <Link.Button
                    href="/"
                    className={`myutmb-register ${styles.register_button}`}
                    onClick={(event) => {
                        if (event?.preventDefault) event.preventDefault();
                        register();
                    }}
                    label={t('common.register')}
                    variant="right"
                    color={ColorTypesEnum.SECONDARY}
                    bgcolor={ColorTypesEnum.PRIMARY}
                />
            </div>

            <div className={styles.separator_not_logged}>
                <Separator color="var(--utmb-color-border-gray)" />
            </div>

            <div className={styles.login_container}>
                <Font.FuturaHeavy desktop="16" as="p">
                    {t('common.alreadyAccount')}
                </Font.FuturaHeavy>
                <Spacer />
                <Link.Default
                    href="/"
                    className={`myutmb-signin ${styles.login_button}`}
                    onClick={(event) => {
                        if (event?.preventDefault) event.preventDefault();
                        login();
                    }}
                    color={ColorTypesEnum.DEFAULT}
                    label={t('common.signIn')}
                    underline={true}
                />
            </div>
        </>
    );
};
