export enum BackgroundColorEnum {
    GREY = 'grey',
    NONE = 'transparent',
    PRIMARY = 'primary',
    RACE = 'race',
    SECONDARY = 'secondary',
    VIRTUAL_CLUB = 'vc',
    WHITE = 'white',
    WHITE_TITLE_PRIMARY = 'whiteTitlePrimary',
}
