import { getLinkForSocialSharing } from '@utmb/utils/social-share';
import { ReactElement, useEffect, useState } from 'react';
// Features
import { Col, Row } from '../../../design-system/box/Box';
import { SocialIcon } from './icons';
// Styles
import styles from './social-share.module.scss';

interface Props {
    /* extra styles */
    className?: string;
    /* variants */
    orientation?: 'horizontal' | 'vertical';
    /* color theme */
    colorTheme?: string;
}

export const SocialShare = (props: Props): ReactElement | null => {
    const { className, orientation = 'horizontal' } = props;
    const Tag = orientation === 'horizontal' ? Row : Col;

    // Socials
    const socials = ['facebook', 'twitter', 'email'];

    return (
        <Tag className={`${styles.container} ${className}`}>
            {socials.map((social: string, index: number) => (
                <ShareItem key={index} social={social} />
            ))}
        </Tag>
    );
};

interface SocialItemProps {
    social: string;
    colorTheme?: string;
}
const ShareItem = (props: SocialItemProps): ReactElement => {
    const { social } = props;
    const [pageUrl, setPageUrl] = useState('');

    useEffect(() => {
        setPageUrl(window.location.href);
    }, []);

    return (
        <a target="_blank" rel="noreferrer noopener" href={getLinkForSocialSharing({ social, pageUrl })}>
            <SocialIcon variant={social.toLowerCase()} />
        </a>
    );
};

SocialShare.Vertical = (props: Props) => <SocialShare {...props} orientation="vertical" />;
