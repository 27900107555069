// React
import { ReactElement } from 'react';
// Next
import { useRouter } from 'next/router';
// Context
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Modules
import classNames from 'classnames';
// Design System
import { Link } from '@utmb/design-system/link/link';
import { useDashboardNavigation } from '@utmb/design-system/use-dashboard-navigation/UseDashboardNavigation';
// Interfacs
import { GlobalNavigationMenuLevelView } from '@utmb/types/index';
// Styles
import styles from '../my-account-dropdown.module.scss';

const cx = classNames.bind(styles);

export const NavigationLinks = (): ReactElement => {
    const { route } = useRouter();
    const { dashboardNavigation } = useDashboardNavigation();
    const {
        global: {
            event: { isWS },
        },
    } = useGlobal();

    // Reset Types for navigation
    const dashboardRoutes = dashboardNavigation as unknown as GlobalNavigationMenuLevelView[];

    return (
        <ul className={styles.links_list}>
            {dashboardRoutes?.map(({ label, slug }, routeIndex) => {
                // Checking the current env to give the right dashboard's URL
                const dashboardLink = isWS ? slug : process.env.NEXT_PUBLIC_UTMB_WORLD_BASE_PATH_URL + slug;

                return (
                    <li
                        className={cx(styles.links_item, {
                            links_item_active: route === slug,
                        })}
                        key={routeIndex}
                    >
                        <Link href={dashboardLink}>{label}</Link>
                    </li>
                );
            })}
        </ul>
    );
};
