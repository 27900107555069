import React, { useEffect, useState, useRef } from 'react';
import { useOverlay, usePreventScroll, useModal } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { useViewportSize } from '../use-viewport-size/UseViewportSize';
import { CoreProps, WithChildren } from '../types';

type TrayProps = CoreProps &
    WithChildren & {
        isOpen?: boolean;
        isDismissable?: boolean;
        onClose: () => void;
        title?: string;
    };

type TraySurface = {
    /** TS complaints unless we broaden the typing */
    [key: string]: string;
};

export const Tray = (props: TrayProps) => {
    const { title, children, className, composeClassName, followClassName, isOpen, ...rest } = props;

    const trayClassNames = ['tray', isOpen && 'is-open', className, composeClassName].filter(Boolean).join(' ');
    const ref: unknown = React.useRef();
    const { isDismissable, onClose, ...extra } = rest;
    const { overlayProps } = useOverlay(rest, ref as React.RefObject<HTMLDivElement>);
    const { modalProps } = useModal();
    const { dialogProps, titleProps } = useDialog(extra, ref as React.RefObject<HTMLDivElement>);
    // const [height, setHeight] = useState(viewportHeight);
    // const timeoutRef = useRef();
    usePreventScroll();

    const viewport = useViewportSize();
    const [height, setHeight] = useState(viewport.height);
    const timeoutRef = useRef();

    useEffect(() => {
        clearTimeout((timeoutRef as any).current);

        // When the height is decreasing, and the keyboard is visible
        // (visual viewport smaller than layout viewport), delay setting
        // the new max height until after the animation is complete
        // so that there isn't an empty space under the tray briefly.
        if (viewport.height < height && viewport.height < window.innerHeight) {
            (timeoutRef as any).current = setTimeout(() => {
                setHeight(viewport.height);
            }, 500);
        } else {
            setHeight(viewport.height);
        }
    }, [height, viewport.height]);

    const trayHeight: TraySurface = { '--tray-min-height': `${height}px` };

    return (
        <div className="underlay">
            <FocusScope contain restoreFocus autoFocus>
                <div
                    {...overlayProps}
                    {...dialogProps}
                    {...modalProps}
                    ref={ref as React.RefObject<HTMLDivElement>}
                    className={trayClassNames}
                    style={trayHeight}
                >
                    <p {...titleProps} className="tray-title offscreen">
                        {title}
                    </p>
                    {children}
                </div>
            </FocusScope>
        </div>
    );
};
