import * as React from 'react';
import { useButton } from '@react-aria/button';
import { CoreProps } from '../types';
import styles from './button.module.scss';
import { ButtonLayout } from './button-layout/button-layout';
import { useRouter } from 'next/router';

interface ButtonDefaultProps extends CoreProps {
    /** Button Type */
    type?: 'button' | 'submit' | 'reset';
    /** OnClick event  */
    onPress?: () => void;
    /** Alignment */
    leftAligned?: boolean;
    rightAligned?: boolean;
    disabled?: boolean;
    id?: string;
    style?: {
        [key: string]: string;
    };
    link?: string;
}

interface ButtonProps extends ButtonDefaultProps {
    label: string;
    color?: string;
    bgcolor?: string;
}

interface ButtonIconProps extends ButtonProps {
    variant?:
        | 'download'

        // Arrow Up
        | 'up'

        // Arrow Down
        | 'down'

        // Arrow Left
        | 'left'

        // Arrow Right
        | 'right'

        // Chevron Down
        | 'chevronDown'

        // Chevron Up
        | 'chevronUp'
        | string;
}

// -----------------------------------------
//
// # Button
//
// # Props
//
//   type?: string;
//   color?: string;
//   bgcolor?: string;
//

export const Button = (props: ButtonDefaultProps): React.ReactElement => {
    // const ref = React.useRef<HTMLButtonElement>(null);
    const router = useRouter();
    const ref = React.useRef(null);
    const { buttonProps } = useButton(props, ref);
    const { className, composeClassName, type = 'button', leftAligned, rightAligned, children, onPress, ...rest } = props;

    const getIsLinkActive = () => {
        if (!props.link) return false;
        return router.asPath === props.link;
    };

    const classNames = [
        styles.button,
        'action cross-center',
        leftAligned ? 'main-start' : rightAligned ? 'main-end' : 'main-center',
        getIsLinkActive() ? styles.activeLink : '',
        composeClassName,
        className,
    ]
        .filter(Boolean)
        .join(' ');

    return (
        <button {...buttonProps} {...rest} className={classNames} ref={ref} type={type}>
            {children}
        </button>
    );
};

//
// -----------------------------------------
//
// # Button - Default
//
// # Props
//
//   label: string;
//   variant?: string;
//   color?: string;
//   bgcolor?: string;
//

Button.Default = (props: ButtonProps) => {
    const { label, color, bgcolor, disabled, composeClassName, className, ...rest } = props;
    const buttonDefaultClasses = [composeClassName, className].filter(Boolean).join(' ');

    return (
        <Button disabled={disabled} composeClassName={buttonDefaultClasses} {...rest}>
            <ButtonLayout label={label} color={color} bgcolor={bgcolor} disabled={disabled} />
        </Button>
    );
};

// -----------------------------------------
//
// # Button - Alternatives

Button.Ghost = (props: ButtonDefaultProps) => <Button {...props} composeClassName={styles.ghost} />;

//
// -----------------------------------------
//
// # Button - Default
//
// # Props
//
//   label: string;
//   variant?: string;
//   color?: string;
//   bgcolor?: string;
//

Button.Icon = (props: ButtonIconProps) => {
    const { label, variant, disabled, color, bgcolor, composeClassName, className, ...rest } = props;
    const buttonDefaultClasses = [composeClassName, className].filter(Boolean).join(' ');

    return (
        <Button disabled={disabled} composeClassName={buttonDefaultClasses} {...rest}>
            <ButtonLayout label={label} variant={variant} color={color} bgcolor={bgcolor} disabled={disabled} />
        </Button>
    );
};
