import { useEffect, useState } from 'react';
// Modules
import { useTranslation } from 'next-i18next';
// Contexts
import { useUser } from '@utmb/contexts/AuthContext';
// Utils
import {
    helpAndContact,
    Management,
    myAchievements,
    myDashboard,
    myEvents,
    myInformation,
    myRegistrations,
    RegistrationConfirmation,
    RegistrationInfomation,
    RegistrationYourQuest,
} from '@utmb/layouts/my-utmb/navigation';
// Types
import { UserAccountTypeEnum } from '@utmb/types/enum/UserAccountTypeEnum';
import { useDashboard } from '@utmb/contexts/DashboardContext';
import MyUtmbNavigationView from '@utmb/types/type/MyUtmbNavigationView';

interface useDashboardNavigationProps {
    dashboardNavigation: MyUtmbNavigationView[] | undefined;
    registrationNavigation: MyUtmbNavigationView[] | undefined;
}

export const useDashboardNavigation = (): useDashboardNavigationProps => {
    const { t } = useTranslation();
    const user = useUser();
    const { isLoginMemberInProgress, dashboardMember, dashboardOrganizer } = useDashboard();

    const [dashboardNavigation, setDashboardNavigation] = useState<MyUtmbNavigationView[] | undefined>();
    const [registrationNavigation, setRegistrationNavigation] = useState<MyUtmbNavigationView[] | undefined>();

    useEffect(() => {
        // Login animation ended and logged in
        if (!user.isLoginInProgress && !isLoginMemberInProgress && user.isAuthenticated && user.me?.email) {
            setNavigation();
        }
    }, [user.isLoginInProgress, isLoginMemberInProgress]);

    const getNavItemTranslation = (navItem: MyUtmbNavigationView): MyUtmbNavigationView => {
        return { ...navItem, label: t(navItem.label) };
    };

    const setNavigation = (): void => {
        const { accountType } = user.me;
        const nav: MyUtmbNavigationView[] = [];
        if (dashboardMember && accountType === UserAccountTypeEnum.MEMBER) {
            // Member logged
            nav.push(getNavItemTranslation(myDashboard));
            nav.push(getNavItemTranslation(myAchievements));
            nav.push(getNavItemTranslation(myRegistrations));
            nav.push(getNavItemTranslation(myInformation));
            nav.push(getNavItemTranslation(helpAndContact));
        } else if (dashboardOrganizer && accountType === UserAccountTypeEnum.ORGANIZER) {
            // Organizer account
            nav.push(getNavItemTranslation(myDashboard));
            nav.push(getNavItemTranslation(myEvents));
            nav.push(getNavItemTranslation(helpAndContact));
        }
        
        if(user?.jwt?.realm_access?.roles?.find((val) => val.includes("admin"))) {
            nav.push(getNavItemTranslation(Management));
        }
        setDashboardNavigation(nav);
        //
        setRegistrationNavigation([
            getNavItemTranslation(RegistrationInfomation),
            getNavItemTranslation(RegistrationYourQuest),
            getNavItemTranslation(RegistrationConfirmation),
        ]);
    };

    return { dashboardNavigation, registrationNavigation };
};
