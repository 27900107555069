export interface getLinkForSocialSharingView {
    social: string;
    pageUrl: string;
    emailPlaceholder?: string;
    emailSubject?: string;
}

export const getLinkForSocialSharing = ({ social, pageUrl, emailPlaceholder = '', emailSubject = '' }: getLinkForSocialSharingView): string => {
    switch (social) {
        case 'facebook':
            return `https://www.facebook.com/sharer.php?u=${pageUrl}`;
        case 'twitter':
            return `https://www.twitter.com/share?&url=${pageUrl}`;
        case 'email':
            return `mailto:${emailPlaceholder}?&subject=${emailSubject}&body=${pageUrl}`;
        default:
            return '';
    }
};
