import * as React from 'react';
import { OverlayContainer, DismissButton } from '@react-aria/overlays';
import { Modal } from './modal';
import { Tray } from './tray';
import { CoreProps, WithChildren } from '../types';
export { DismissButton };
type OverlayProps = CoreProps &
    WithChildren & {
        isDismissable?: boolean;
        isOpen?: boolean;
        onClose: () => void;
        title?: string;
        variant?: 'tray' | 'modal';
    };

export const Overlay = (props: OverlayProps) => {
    const { variant, ...extra } = props;
    return (
        <OverlayContainer>
            {
                {
                    modal: <Modal {...extra} />,
                    tray: <Tray {...extra} />,
                }[variant!]
            }
        </OverlayContainer>
    );
};

Overlay.Modal = (props: OverlayProps) => <Overlay {...props} variant="modal" />;
Overlay.ModalXS = (props: OverlayProps) => <Overlay {...props} variant="modal" composeClassName="is-xs" />;
Overlay.Tray = (props: OverlayProps) => <Overlay {...props} variant="tray" />;
Overlay.TrayMax = (props: OverlayProps) => <Overlay {...props} variant="tray" composeClassName="is-max" />;
