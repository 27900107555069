// React
import { ReactElement } from 'react';
// Modules
import classNames from 'classnames/bind';
// Utils
import { rem } from '@utmb/utils/font';
// Styles
import styles from './placeholder.module.scss';

const cx = classNames.bind(styles);

interface PlaceholderProps {
    className?: string;
    logoWidth?: number;
    logoHeight?: number;
    width?: string;
    height?: string;
    isInvert?: boolean;
}
interface SkeletonProps {
    className?: string;
    width?: string;
    height?: string;
}

export const Placeholder = (props: PlaceholderProps): ReactElement => {
    const { className, logoWidth = 120, logoHeight = 120, width = '100%', height = '100%', isInvert = false } = props;

    const rootCSS = cx(className, styles.placeholder, {
        placeholder_bg_primary: !isInvert,
        placeholder_bg_secondary: isInvert,
    });

    return (
        <div className={rootCSS} style={{ width, height }}>
            <svg width={logoWidth} height={logoHeight} viewBox="0 0 24 24">
                <path d="m16.765 2-5.016 11.014-5.552-9.925L2 20.73h4.102l1.485-7.53L11.75 22l4.017-9.294 1.976 8.023H22z" fillRule="evenodd" />
            </svg>
        </div>
    );
};

export const Skeleton = (props: SkeletonProps): ReactElement => {
    const { className, width = '90%', height = '10px' } = props;

    const rootCSS = cx(styles.skeleton, className);

    return <div className={rootCSS} style={{ width, height: rem(height) }} />;
};
