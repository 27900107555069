import variables from '@utmb/styles/common/common.module.scss';

/**
 * Convert a pixel value to rem
 *
 * @param value Value in pixels to convert in rem
 *
 * @returns The value converted in rem
 */
export function rem(value: string | number): string {
    return `${parseInt(value.toString()) / parseInt(variables.basePixels)}rem`;
}
