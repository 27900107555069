import { ColorTypesEnum } from '@utmb/types/colors';
import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
import variables from '@utmb/styles/common/common.module.scss';
import { BackgroundColorEnum } from '@utmb/types/enum/BackgroundColorEnum';
import { LinkPositionEnum } from '@utmb/types/enum/LinkPositionEnum';

/**
 * Get the color depending of the parameter passed.
 *
 * If the parameter is of type `ColorTypesEnum`, a CSS var will be returned.
 * Otherwide returns the given hexa color.
 *
 * @param {string | ColorTypesEnum} color
 *
 * @returns {string}
 */
export const getColorStyle = (color: string | ColorTypesEnum): string => {
    const isHexa = !(<any>Object).values(ColorTypesEnum).includes(color);

    return isHexa ? color : `var(--utmb-color-${color})`;
};

export const isColorTypesEnum = (color: string | ColorTypesEnum): boolean => {
    return (<any>Object).values(ColorTypesEnum).includes(color);
};

/**
 * Get the color depending of the parameter passed.
 *
 * If the parameter is of type `PiCategoryEnum`, a Hexa value will be returned.
 *
 * @param {PiCategoryEnum} category
 *
 * @returns {string}
 */
export const getCategoryColor = (category: PiCategoryEnum): string =>
    ({
        [PiCategoryEnum.GENERAL]: variables['color-category-general'],
        [PiCategoryEnum.PI20K]: variables['color-category-20k'],
        [PiCategoryEnum.PI50K]: variables['color-category-50k'],
        [PiCategoryEnum.PI100K]: variables['color-category-100k'],
        [PiCategoryEnum.PI100M]: variables['color-category-100m'],
    }[category]);

/**
 * Get the hex color of a CSS variable.
 *
 * This function can only be called in the browser.
 *
 * @param {ColorTypesEnum} color
 *
 * @returns {string | undefined} The hex color if found, undefined otherwise.
 */
export const getHex = (color: ColorTypesEnum): string | undefined => {
    if (typeof window !== 'undefined') {
        const style = getComputedStyle(document.body);

        return style.getPropertyValue(`--utmb-color-${color}`);
    }

    return undefined;
};

export const LightenDarkenColor = (col: string, pct = -20): string => {
    let useDash = false;

    if (col[0] == '#') {
        col = col.slice(1);
        useDash = true;
    }

    const num = parseInt(col, 16);
    const mul = (100 + pct) / 100;

    let r = Math.round((num >> 16) * mul);

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = Math.round(((num >> 8) & 0x00ff) * mul);

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = Math.round((num & 0x0000ff) * mul);

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (useDash ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const getColorAndBgColorButton = (
    primaryColorIsDark: boolean,
    secondaryColorIsDark: boolean,
    mainColor: ColorTypesEnum | null = null
): [ColorTypesEnum, ColorTypesEnum] => {
    let color = ColorTypesEnum.WHITE;
    let bgcolor = mainColor;
    if (bgcolor && [ColorTypesEnum.BLACK, ColorTypesEnum.GREY, ColorTypesEnum.DEFAULT, ColorTypesEnum.RED, ColorTypesEnum.WS_PRIMARY].includes(bgcolor)) {
        color = ColorTypesEnum.WHITE;
    } else if (bgcolor && [ColorTypesEnum.WHITE, ColorTypesEnum.WS_SECONDARY, ColorTypesEnum.VIRTUAL_CLUB, ColorTypesEnum.WS_VIRTUAL_CLUB].includes(bgcolor)) {
        color = ColorTypesEnum.DEFAULT;
    } else if (primaryColorIsDark && secondaryColorIsDark) {
        if (!bgcolor) bgcolor = ColorTypesEnum.PRIMARY;
        color = ColorTypesEnum.WHITE;
    } else if (!primaryColorIsDark && !secondaryColorIsDark) {
        if (!bgcolor) bgcolor = ColorTypesEnum.PRIMARY;
        color = ColorTypesEnum.DEFAULT;
    } else if (!bgcolor) {
        if (!primaryColorIsDark) {
            bgcolor = ColorTypesEnum.SECONDARY;
            color = ColorTypesEnum.PRIMARY;
        } else {
            color = ColorTypesEnum.SECONDARY;
            bgcolor = ColorTypesEnum.PRIMARY;
        }
    } else {
        if (bgcolor === ColorTypesEnum.PRIMARY) color = ColorTypesEnum.SECONDARY;
        else color = ColorTypesEnum.PRIMARY;
    }
    return [color, bgcolor];
};

export const computeColorsFromBackgroundColor = (
    mainColor: BackgroundColorEnum,
    isDark: boolean,
    primaryColorIsDark: boolean,
    secondaryColorIsDark: boolean,
    linkPosition: LinkPositionEnum | null = null
): { textColor: string; titleColor: string; backgroundColor: string; buttonColor: string; buttonBgColor: string } => {
    let textColor = getColorStyle(ColorTypesEnum.DEFAULT);
    if (mainColor === BackgroundColorEnum.RACE) {
        if (isDark) textColor = getColorStyle(ColorTypesEnum.WHITE);
    } else {
        if ([BackgroundColorEnum.NONE, BackgroundColorEnum.GREY, BackgroundColorEnum.WHITE, BackgroundColorEnum.VIRTUAL_CLUB].includes(mainColor)) {
            if (primaryColorIsDark) textColor = getColorStyle(ColorTypesEnum.PRIMARY);
            else if (secondaryColorIsDark) textColor = getColorStyle(ColorTypesEnum.SECONDARY);
        } else if (mainColor === BackgroundColorEnum.WHITE_TITLE_PRIMARY) {
            if (secondaryColorIsDark) textColor = getColorStyle(ColorTypesEnum.SECONDARY);
        } else if (mainColor === BackgroundColorEnum.PRIMARY) {
            if (primaryColorIsDark) {
                if (secondaryColorIsDark) textColor = getColorStyle(ColorTypesEnum.WHITE);
                else textColor = getColorStyle(ColorTypesEnum.SECONDARY);
            } else if (secondaryColorIsDark) textColor = getColorStyle(ColorTypesEnum.SECONDARY);
        } else if (mainColor === BackgroundColorEnum.SECONDARY) {
            if (secondaryColorIsDark) {
                if (primaryColorIsDark) textColor = getColorStyle(ColorTypesEnum.WHITE);
                else textColor = getColorStyle(ColorTypesEnum.PRIMARY);
            } else if (primaryColorIsDark) textColor = getColorStyle(ColorTypesEnum.PRIMARY);
        }
    }
    const titleColor = mainColor === BackgroundColorEnum.WHITE_TITLE_PRIMARY ? getColorStyle(ColorTypesEnum.PRIMARY) : textColor;

    const backgroundColor =
        mainColor === BackgroundColorEnum.RACE
            ? getColorStyle(ColorTypesEnum.RACE_PRIMARY)
            : getColorStyle(mainColor === BackgroundColorEnum.WHITE_TITLE_PRIMARY ? BackgroundColorEnum.WHITE : mainColor);

    let buttonBgColor = getColorStyle(ColorTypesEnum.DEFAULT);
    let buttonColor = getColorStyle(ColorTypesEnum.WHITE);
    if (mainColor === BackgroundColorEnum.RACE) {
        buttonBgColor = getColorStyle(ColorTypesEnum.RACE_PRIMARY);
        buttonColor = isDark ? getColorStyle(ColorTypesEnum.WHITE) : getColorStyle(ColorTypesEnum.DEFAULT);
    } else {
        if (linkPosition === LinkPositionEnum.OVER_IMAGE) {
            buttonBgColor = getColorStyle(ColorTypesEnum.PRIMARY);
            if (primaryColorIsDark) {
                buttonColor = secondaryColorIsDark ? getColorStyle(ColorTypesEnum.WHITE) : getColorStyle(ColorTypesEnum.SECONDARY);
            } else {
                buttonColor = secondaryColorIsDark ? getColorStyle(ColorTypesEnum.SECONDARY) : getColorStyle(ColorTypesEnum.DEFAULT);
            }
        } else if (
            [
                BackgroundColorEnum.NONE,
                BackgroundColorEnum.GREY,
                BackgroundColorEnum.WHITE,
                BackgroundColorEnum.VIRTUAL_CLUB,
                BackgroundColorEnum.WHITE_TITLE_PRIMARY,
                BackgroundColorEnum.SECONDARY,
            ].includes(mainColor)
        ) {
            buttonBgColor = getColorStyle(ColorTypesEnum.PRIMARY);
            if (primaryColorIsDark) {
                buttonColor = secondaryColorIsDark ? getColorStyle(ColorTypesEnum.WHITE) : getColorStyle(ColorTypesEnum.SECONDARY);
            } else {
                buttonColor = secondaryColorIsDark ? getColorStyle(ColorTypesEnum.SECONDARY) : getColorStyle(ColorTypesEnum.DEFAULT);
            }
        } else if (mainColor === BackgroundColorEnum.PRIMARY) {
            buttonBgColor = getColorStyle(ColorTypesEnum.SECONDARY);
            if (secondaryColorIsDark) {
                buttonColor = primaryColorIsDark ? getColorStyle(ColorTypesEnum.WHITE) : getColorStyle(ColorTypesEnum.PRIMARY);
            } else {
                buttonColor = primaryColorIsDark ? getColorStyle(ColorTypesEnum.PRIMARY) : getColorStyle(ColorTypesEnum.DEFAULT);
            }
        }
    }

    return { textColor, titleColor, backgroundColor, buttonColor, buttonBgColor };
};
