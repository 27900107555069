// React
import { ReactElement } from 'react';
// Modules
import classNames from 'classnames/bind';
// Design Systems
import { Link } from '@utmb/design-system/link/link';
import { Icon } from '@utmb/design-system/icons/Icon';
// Types
import { BottomBarElementProps } from '@utmb/types/mobile-bottom-bar';
// Styles
import styles from './bottom-bar-element.module.scss';
import { IconsEnum } from '@utmb/types/icons';

const cx = classNames.bind(styles);

export const BottomBarElement = (props: BottomBarElementProps): ReactElement => {
    const { className, label, icon, link, onClick } = props;

    const containerCSS = cx(styles.menu_item_container, className);

    return (
        <div className={containerCSS}>
            <Link onClick={onClick} className={styles.menu_item_link} href={link || '/'}>
                <Icon color="var(--utmb-color-blue-light)" variant={icon as IconsEnum} />
                <div className={styles.menu_item_label}>{label}</div>
            </Link>
        </div>
    );
};
