import * as React from 'react';
import { useSeparator } from '@react-aria/separator';
// Interfaces
import { ColorTypesEnum } from '@utmb/types/colors';
// Utils
import { getColorStyle } from '@utmb/utils/colors';
import { rem } from '@utmb/utils/font';

type SeparatorProps = {
    /** fill */
    color?: string;
    className?: string;
    /** fine tunnig with css margin */
    spacing?: string;
    /** orientation */
    orientation?: 'vertical' | 'horizontal';
    /** opacity */
    opacity?: string;
    /** if orientation is vertical weight affects width, else height */
    weight?: string;
    /** if orientation is vertical depth affects height, else width */
    depth?: string;
};

export const Separator = (props: SeparatorProps) => {
    const { spacing = undefined, className = undefined } = props;
    const { separatorProps } = useSeparator(props);

    return (
        <div
            {...separatorProps}
            className={className}
            style={{
                pointerEvents: 'none',
                background: getColorStyle(props.color ?? ColorTypesEnum.WHITE),
                opacity: props.opacity,
                width: props.orientation === 'vertical' ? props.weight : props.depth,
                height: props.orientation === 'vertical' ? props.depth : props.weight,
                minHeight: props.orientation === 'vertical' ? props.depth ?? rem(100) : rem(1),
                ...(spacing && { margin: spacing }),
                maxWidth: '100%',
            }}
        />
    );
};

Separator.Vertical = (props: SeparatorProps) => <Separator {...props} orientation="vertical" />;

Separator.defaultProps = {
    orientation: 'horizontal',
    weight: rem(1),
    depth: '100%',
    color: 'currentColor',
} as Partial<SeparatorProps>;
