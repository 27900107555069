import { useEffect, useState } from 'react';

const canVisualViewport = () => typeof window.visualViewport !== 'undefined';

export const useViewportSize = () => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const getViewportSize = () => {
            return {
                width: (window?.visualViewport?.width ?? window?.innerWidth) || 0,
                height: (window?.visualViewport?.height ?? window?.innerHeight) || 0,
            };
        };

        // Use visualViewport api to track available height even on iOS virtual keyboard opening
        const onResize = () => {
            setSize(getViewportSize());
        };
        // Set values on load
        onResize();

        if (!canVisualViewport()) {
            window.addEventListener('resize', onResize);
        } else {
            visualViewport.addEventListener('resize', onResize);
        }

        return () => {
            if (!canVisualViewport()) {
                window.removeEventListener('resize', onResize);
            } else {
                visualViewport.removeEventListener('resize', onResize);
            }
        };
    }, []);

    return size;
};
