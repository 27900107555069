import { IconsEnum } from '@utmb/types/icons';
import MyUtmbNavigationView from '@utmb/types/type/MyUtmbNavigationView';

export const myAchievements: MyUtmbNavigationView = {
    icon: IconsEnum.MY_ACHIEVEMENT,
    id: 3,
    label: 'myUtmb.navigation.achievements',
    slug: '/my-utmb/my-achievements',
};

export const myRegistrations: MyUtmbNavigationView = {
    icon: IconsEnum.MY_REGISTRATION,
    id: 2,
    label: 'myUtmb.navigation.registrations',
    slug: '/my-utmb/my-registrations',
};

export const myDashboard: MyUtmbNavigationView = {
    icon: IconsEnum.MY_DASHBOARD,
    id: 1,
    label: 'myUtmb.navigation.dashboard',
    slug: '/my-utmb/my-dashboard',
};

export const myInformation: MyUtmbNavigationView = {
    icon: IconsEnum.MY_INFORMATION,
    id: 4,
    label: 'myUtmb.navigation.information',
    slug: '/my-utmb/my-information',
};

export const helpAndContact: MyUtmbNavigationView = {
    icon: IconsEnum.HELP,
    id: 5,
    label: 'myUtmb.navigation.contact',
    slug: '/my-utmb/help-and-contact',
};

export const RegistrationInfomation: MyUtmbNavigationView = {
    icon: IconsEnum.MY_INFORMATION,
    id: 6,
    label: 'myUtmb.registration.information',
    slug: '/my-utmb/registration/information',
    disabled: true,
};

export const RegistrationYourQuest: MyUtmbNavigationView = {
    icon: IconsEnum.MOUNTAIN,
    id: 7,
    label: 'myUtmb.registration.yourQuest',
    slug: '/my-utmb/registration/your-quest',
    disabled: true,
};

export const RegistrationConfirmation: MyUtmbNavigationView = {
    icon: IconsEnum.BOOKMARK_CHECKED,
    id: 8,
    label: 'myUtmb.registration.confirmation',
    slug: '/my-utmb/registration/confirmation',
    disabled: true,
};

export const myEvents: MyUtmbNavigationView = {
    icon: IconsEnum.RACE,
    id: 9,
    label: 'myUtmb.navigation.manage-events',
    slug: 'https://dashboard.utmb.world/events/all',
};

export const Management: MyUtmbNavigationView = {
    icon: IconsEnum.ADVICE,
    id: 99,
    label: 'myUtmb.navigation.management',
    slug: '/my-utmb/management',
};
