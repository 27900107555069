import { forwardRef, LegacyRef, ReactElement, RefObject, useEffect, useRef } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
// Types
import { EventView, CloudinaryMediaView } from '@utmb/types/index';
import { ColorTypesEnum } from '@utmb/types/colors';
// Utils
import { getColorStyle } from '@utmb/utils/colors';
// Styles
import styles from './mega-nav.module.scss';
import { CloudinaryTransformEnum } from '@utmb/types/cloudinary-transform.enum';
import { Icon } from '@utmb/design-system/icons/Icon';
import { IconsEnum } from '@utmb/types/icons';

interface MegaNavProps {
    isOpen: boolean;
    eventsTopBar: EventView[];
    handleClose: (e: any) => boolean;
}

const MegaNavDesktop = forwardRef((props: MegaNavProps, ref: LegacyRef<HTMLDivElement>): ReactElement => {
    const { isOpen, eventsTopBar, handleClose } = props;

    const { t } = useTranslation();

    const eventsByContinents: Record<string, EventView[]> = {};

    for (const event of eventsTopBar) {
        if (!(event.continent in eventsByContinents)) {
            eventsByContinents[event.continent] = [];
        }

        eventsByContinents[event.continent].push(event);
    }

    const closeRef = useRef<HTMLDivElement>(null);

    function handleKeyUpEvent(e: KeyboardEvent): void {
        const element = (ref as RefObject<HTMLDivElement>).current;

        if (e.key === 'Tab' && !element?.contains(document.activeElement)) {
            handleClose(true);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => closeRef.current?.focus(), 100);

            document.addEventListener('keyup', handleKeyUpEvent);

            return () => {
                document.removeEventListener('keyup', handleKeyUpEvent);
            };
        }
    });

    const containerCSS = [styles.root, isOpen ? styles.show : styles.hide].filter(Boolean).join(' ');

    return (
        <div ref={ref} className={containerCSS}>
            <div className={`${styles.container} container`}>
                <div className={`${styles.heading} row`}>
                    <div className={`${styles.heading_button} col-2`}>
                        <div role="button" tabIndex={0} ref={closeRef} className={styles.heading_button_wrapper} onClick={handleClose} onKeyDown={handleClose}>
                            <Icon variant={IconsEnum.LEFT} />
                        </div>
                    </div>
                    <div className="col-10">
                        <div className={styles.title}>
                            <Font.FuturaMedium desktop="32" className="lh-d-40">
                                {t('meganav.title')}
                            </Font.FuturaMedium>
                        </div>
                        <Spacer.size30 />
                        <div className={styles.summary}>
                            <Font.FuturaLight desktop="18" className="lh-d-28">
                                {t('meganav.summary')}
                            </Font.FuturaLight>
                        </div>
                    </div>
                </div>

                {Object.values(eventsByContinents).map((event, indexContinent: number) => (
                    <div key={indexContinent} className={`${styles.region_section} row`}>
                        <div className={`${styles.region_name} col-2`}>
                            <Font.FuturaHeavy mobile="26" desktop="36" className="lh-d-40">
                                {event[0].continent}
                            </Font.FuturaHeavy>
                        </div>
                        <div className="col-10">
                            <div className="row">
                                {event.map((race, indexEvent: number) => {
                                    const media = {
                                        publicId: race.picture,
                                    } as CloudinaryMediaView;

                                    const logo = {
                                        publicId: race.logo,
                                    } as CloudinaryMediaView;

                                    return (
                                        <div key={indexEvent} className={`${styles.image_wrapper} col-12 col-lg-6 col-xl-4 col-xxl-3`}>
                                            <Link className={styles.race_link} href={race.url}>
                                                <div className={styles.overlay}>
                                                    <div className={styles.date}>
                                                        {race?.dateFormated && (
                                                            <Font.OxaniumBold desktop="14" className="lh-d-24">
                                                                {race.dateFormated}
                                                            </Font.OxaniumBold>
                                                        )}
                                                    </div>
                                                    <div className={styles.utmb_icon}>
                                                        {race.logo ? (
                                                            <Image
                                                                objectFit="contain"
                                                                layout="fill"
                                                                image={logo}
                                                                addOptions={[CloudinaryTransformEnum.TRIM]}
                                                                maxWidth={165}
                                                            />
                                                        ) : (
                                                            <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={IconsEnum.UTMB_GROUP} />
                                                        )}
                                                    </div>
                                                    <div className={styles.flag}>
                                                        {race?.countryCode && <span className={`fi fi-${race.countryCode.toLowerCase()}`} />}
                                                    </div>
                                                </div>
                                                {!race?.picture ? (
                                                    <img
                                                        className={styles.placeholder}
                                                        src="https://via.placeholder.com/400x150/00DBFF/000000?text=Placeholder"
                                                        alt="placeholder"
                                                    />
                                                ) : (
                                                    <Image className={styles.image} objectFit="cover" layout="fill" image={media} maxWidth={300} />
                                                )}
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default MegaNavDesktop;
