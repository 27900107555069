// React
import { ReactElement } from 'react';
// Next
import dynamic from 'next/dynamic';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Box, Row } from 'src/design-system/box/Box';
import { Font } from '@utmb/design-system/text/font';
import { IcoAnimated as ComponentType } from '@utmb/design-system/animated-icon/AnimatedIcon';
import { IcoAnimatedProps } from '@utmb/design-system/animated-icon/AnimatedIcon';
import { Icon } from '@utmb/design-system/icons/Icon';
// Types
import { CoreProps } from '@utmb/design-system/types';
import { ColorTypesEnum } from '@utmb/types/colors';
import { IconsEnum } from '@utmb/types/icons';
// Context, Utils
import { getColorStyle } from '@utmb/utils/colors';
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Styles
import styles from './button-layout.module.scss';

const { FuturaHeavy } = Font;

const NoSSRAnimatedIcon = dynamic<IcoAnimatedProps>(() => import('../../animated-icon/AnimatedIcon').then((comp) => comp.IcoAnimated as any), {
    ssr: false,
}) as typeof ComponentType;

interface ButtonLayoutProps extends CoreProps {
    label: string;
    variant?:
        | 'download'

        // Arrow Up
        | 'up'

        // Arrow Down
        | 'down'

        // Arrow Left
        | 'left'

        // Arrow Right
        | 'right'

        // Chevron Down
        | 'chevronDown'

        // Chevron Up
        | 'chevronUp'
        | string;
    color?: string;
    bgcolor?: string;
    disabled?: boolean;
}

export const ButtonLayout = (props: ButtonLayoutProps): ReactElement => {
    const { global } = useGlobal();
    const secondaryColorIsDark = global?.event?.secondaryColorIsDark || true;

    const {
        label,
        variant,
        color = secondaryColorIsDark ? ColorTypesEnum.WHITE : ColorTypesEnum.WS_PRIMARY,
        bgcolor = getColorStyle(ColorTypesEnum.SECONDARY),
        disabled,
    } = props;

    const disabledColor = getColorStyle(ColorTypesEnum.GREY);

    const cx = classNames.bind(styles);

    return (
        <Box
            className={cx(styles.button, {
                button_disabled: disabled,
            })}
            style={{ backgroundColor: disabled ? disabledColor : bgcolor }}
        >
            <Row className="cross-center">
                <FuturaHeavy mobile="16" className="ls-button" style={{ color: color }}>
                    {label}
                </FuturaHeavy>
                {variant && (
                    <NoSSRAnimatedIcon x={2} rotation={-20} timing={200}>
                        <Icon color={color} variant={variant as IconsEnum} />
                    </NoSSRAnimatedIcon>
                )}
            </Row>
        </Box>
    );
};
