// React
import { ReactElement } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Separator } from '@utmb/design-system/separator/Separator';
// Interfaces
import { IconsEnum } from '@utmb/types/icons';
// Styles
import styles from '../my-account-dropdown.module.scss';
import { useUser } from '@utmb/contexts/AuthContext';

export const SignOutButton = (): ReactElement => {
    const { logout } = useUser();

    const { t } = useTranslation();

    return (
        <>
            <Separator className={styles.separator} color="var(--utmb-color-border-gray)" />
            <a href="#" className={styles.logout_link} onClick={logout}>
                <Icon variant={IconsEnum.LOGOUT} />
                <Font.FuturaBook className={styles.menu_user_logout_link} mobile="12" desktop="12" as="span">
                    {t('common.signOut')}
                </Font.FuturaBook>
            </a>
        </>
    );
};
